@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-bg {
  background-image: url('./assets/background_header.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 105px);
}

.home-img {
  max-height: 75vh;
}

.esquina-bg {
  min-height: calc(100vh - 490px);
  background-image: url(./assets/background_content.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  padding-bottom: 4rem;
}

.esquina-heading {
  font-family: 'Abril Fatface', cursive;
  font-size: 2rem;
}

.item-name-heading {
  min-height: 4rem;
}

li p, .esquina-language {
  font-family: 'Amatic SC', cursive;
  font-size: 2rem;
}

.description {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
}

.spices-img img {
  height: 25px;
}

@media only screen and (max-width: 1024px) {
  .esquina-bg {
    padding-left: 15px;
    padding-right: 15px;
  }
}